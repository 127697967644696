.ContactContents{
    padding-top: 6%;
    font-family: 'Noto Sans KR', sans-serif;
}
.ContactContents .ContactTopTxt{
    text-align: center;
}
.ContactContents .ContactTopTxt h1{
    color: #01BCD6;
    margin-block-start: 0;
}
.ContactContents .ContactTopTxt p{
    color: #fff;
    margin: 3% 0 3%;
}

/* 신청폼 */
.ContactFormBox{
    display: flex;
    justify-content: center;
}
#contact-form{
    width: 100%;
}

.ContactFormBox form{
    background: linear-gradient(to bottom, rgb(24, 57, 103),  rgb(28, 62, 108, 0));
    padding: 4rem 7rem;
}
.ContactFormBox form .form-group{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin: 3% auto;
}
.ContactFormBox form .form-group:nth-child(3){
    align-items: start;
}
.ContactFormBox form label{
    color: #01BCD6;
    width: 25%;
}
.ContactFormBox form input{
    width: 75%;
    background-color: #122239;
    padding: 1rem;
    color:white;
}
.ContactFormBox form textarea{
    width: 75%;
    background-color: #122239;
    color:white;
    resize: none;
    font-family: 'Noto Sans KR', sans-serif;

}
.ContactFormBox #contact-form button{
    width: 35%;
    margin: 10% auto 0;
    border-radius: 50px;
    background-color: #01BCD6;
    color: #fff;
    cursor: pointer;
    padding: 2%;
    border: none;
    display: block;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 1rem;
    transition: background-color 0.3s;
    font-family: 'Noto Sans KR', sans-serif;

}
.ContactFormBox #contact-form button:hover{
    background-color: #162f52;
}
