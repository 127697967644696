.Home{
    background: url(../../images/background_big.png) no-repeat;
    background-position: center 17%;
    /* background-position: center center; */
}
.MainSlide{}

/* 퀵메뉴 이미지 */
.menu-bg {
    transition: background 0.5s ease-in-out;
}
  
.default {
    background: url('../../images/MainSlide01.png') no-repeat ;
    background-position: right ;
}
  
.image1 {
    background: url('../../images/MainSlide02.png') no-repeat;
    background-position: right ;
}
  
.image2 {
    background: url('../../images/MainSlide03.png') no-repeat;
    background-position: right ;
}
  
.image3 {
    background: url('../../images/MainSlide04.png') no-repeat;
    background-position: right ;
}
.image4 {
    background: url('../../images/MainSlide05.png') no-repeat;
    background-position: right ;
}

/* 텍스트 */
.MainSlideTxt{
    width: 64.1%;
    margin: 0 auto;
    color: #fff;
    padding-top: 20%;
}
.MainSlideTxt h1{
    font-size: calc(31px + 4vmin);
    line-height: 1.2em;
    margin-block-start: 0;
    transform: translateY(-70px);
}
.MainSlideTxt p{
    color: #fff;
    margin: 2% 0 4%;
    transform: translateY(-50px);
}

.menu-bg .quickMenu{
    display: flex;
    justify-content: space-evenly;
    background: linear-gradient(to bottom, rgb(22, 47, 82 ,1),  rgba(22, 47, 82, 0));
    width: 55%;
    padding: 2% 0 2% 12%;
    border-radius: 0 24px 24px 0;
}
.menu-bg .quickMenu a{
    text-decoration: none;
}
.menu-bg .quickMenu a > li{
    list-style: none;
    cursor: pointer;
    color: #01BCD6;
    font-size: calc(10px + 1.5vmin);
    font-weight: bold;
}
/* 퀵메뉴 */
.quickSubMenu{
    padding-inline-start: 0;
    margin-top: 10%;
}
.quickSubMenu >li{
    list-style: none;
    color: #fff;
    font-size: calc(7px + 1vmin);
    font-weight: normal;
    padding: 3% 0;
}

/* =========중간섹션============ */
.HomeContents{
}
.HomeContainer{
    width: 64.1%;
    margin: 0 auto;
    padding-bottom: 3%;
}
/* 윗부분 내용 */
.HomeContents .TopContents{
    margin: 15% 0;
}
.HomeContents .TopContents img{
    margin: 0 auto;
    display: block;
}
.HomeContents .TopContents .centerTxt{
    color: #fff;
    text-align: center;
}
.HomeContents .TopContents .centerTxt h1{
    margin-block-start: 2em;
}
.HomeContents .TopContents .centerTxt h1 span{
    color: #01BCD6;
}
.app-icon {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid #e0e0e0;
    transition: all 0.3s ease; /* 모든 변화에 부드러운 전환 효과 */
}

.app-icon:hover {
    transform: scale(1.1);  /* 10% 크게 */
}

.toggle-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
    transition: transform 0.3s ease;
    transform: rotate(0deg); /* 초기 상태 명시 */
}

.toggle-icon:hover {
    transform: rotate(90deg);
}

@keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(10deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-10deg); }
    100% { transform: rotate(0deg); }
}

/* 아랫부분 내용 */
.HomeContents .BottomContents{}
.HomeContents .BottomContents h2{
    color: #01BCD6;
    text-align: center;
    font-size: calc(12px + 3vmin);
    margin: 15% 0;
}
.HomeContents .BottomContents .coreValues{
    display: flex;
    justify-content: space-between;
}
.HomeContents .BottomContents .coreValues .coreValuseBox{
    width: 30%;
    background: linear-gradient(to bottom, rgb(22, 47, 82 ,1),  rgba(22, 47, 82, 0));
    border-radius: 24px;
    padding: 0 1% 1%;
}
.HomeContents .BottomContents .coreValues .coreValuseBox img{
    display: block;
    margin: -35% auto 10%;
}
.HomeContents .BottomContents .coreValues .coreValuseBox:nth-child(2) img{
    margin-bottom: -2.5%;
}
.HomeContents .BottomContents .coreValues .coreValuseBox:nth-child(3) img{
    /* margin-bottom: 20%; */
}
.HomeContents .BottomContents .coreValues .coreValuseBox h3{
    color: #01BCD6;
    text-align: center;
}
.HomeContents .BottomContents .coreValues .coreValuseBox p{
    color: #fff;
}
/* PC 화면용 미디어 쿼리 추가 */
@media screen and (min-width: 1025px) {
    .Home {
      background-position: center 0; /* 배경 이미지 위치 조정 */
    }
  
    .MainSlideTxt {
      padding-top: 10%; /* 패딩 줄임 */
      position: relative;
      z-index: 1;
    }
  
    .MainSlideTxt h1 {
      transform: translateY(0); /* 텍스트 위치 조정 */
    }
  
    .MainSlideTxt p {
      transform: translateY(0);
    }
  
    .menu-bg .quickMenu {
        position: relative;  /* fixed 대신 relative 사용 */
        top: 0;  /* 상단에 위치 */
        width: 55%;  /* 원래 너비 유지 */
        padding: 2% 0 2% 12%;  /* 원래 패딩 유지 */
        background: linear-gradient(to bottom, rgb(22, 47, 82 ,1), rgba(22, 47, 82, 0));
        border-radius: 0 24px 24px 0;
        margin-top: 0;
    }
  
    .quickSubMenu {
      margin-top: 5%;
    }
  }

/* ============모바일============ */
/* @media (max-width: 1252px){
    .menu-bg .quickMenu{
        width: 100%;
        padding: 2% 0;
        border-radius: 24px 24px 0 0;
    }
    .HomeContainer{
        width: 80%;
    }
} */
