.tab-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    padding: 10px;
    background-color: #f1f1f1;
    box-shadow: 2px 0 5px rgba(0,0,0,0.5);
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    z-index: 1000; /* 이 부분을 추가 */
  }
  
  .tab-container.open {
    transform: translateX(0);
    overflow: auto;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
  
    to {
      transform: translateX(0);
    }
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }
  
  .overlay.open {
    display: block;
  }