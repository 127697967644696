.BusinessContents{
    /* background: url(../../images/background_big_Service.png) no-repeat;
    background-position: 0 17%; */
    padding-top: 0;
    font-family: 'Noto Sans KR', sans-serif;
    background: url(../../images/background_big.png) no-repeat;
    background-position: center 50%;
}
.BusinessContents .BusinessTopTxt{
    text-align: center;
}
.BusinessContents .BusinessTopTxt h1{
    color: #01BCD6;
    margin-block-start: 0;
    /* margin-top: 10%; */
}
.BusinessContents .BusinessTopTxt h1:nth-child(2){
    color: #fff;
}
.BusinessContents .BusinessTopTxt p{
    color: #fff;
    margin: 3% 0 5%;
}
.mainIntro {
    font-size: 10px;
    font-weight: bold;
    padding-top: 4vw;
  }
/* 중간 텍스트 */
.BusinessContents .BusinessMidTxt{
    text-align: center;
}
.BusinessContents .BusinessMidTxt h1{
    color: #01BCD6;
    margin-block-start: 0;
}
.BusinessContents .BusinessMidTxt p{
    color: #fff;
    margin: 3% 0;
}
.BusinessContents .BusinessMidTxt p span{
    font-weight: bold;
}

/* 마지막 이미지 */
.BusinessMidTxt {
    margin-top: 5%;
}
.BusinessContents .BusinessBottom{
    margin: 10% 0;
}
.BusinessContents .BusinessBottom img{
    width: 100%;
}



.imgslider {
    width: 400px; 
    height: 400px; 
    position: relative;
    perspective: 800px;
    transform-style: preserve-3d;
    margin: auto;
  }
  
  
  .imgslider img {
    position: absolute;
    /* 위치가 안맞아서 임의 조정 */
    left: 13%;
    top: 30%;
    width: 300px; 
    height: auto;
    transform-origin: center;
    transition: transform 1s ease;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 1rem;
  }
  .slider-arrow {
    position: absolute;
    transform: translateY(-50%); 
    color: #ffffff28;
    background-color: transparent;
    font-size: 3rem;
    border: none; 
    cursor: pointer; 
    padding: 10px; 
    z-index: 10;
    top: 50%; /* Center vertically */

}

.slider-arrow.left {
    left: -400px;
}

.slider-arrow.right {
    right: -400px; 
}


  @media (max-width: 760px) {
    .imgslider {
        width: 180px; 
        height: 180px; 
        position: relative;
        perspective: 1000px;
      }
      .imgslider img {
        position: absolute;
        /* 위치가 안맞아서 임의 조정 */
        left: 0%;
        top: 30%;
        width: 180px; 
      }
      .BusinessMidTxt {
        margin-top: 15%;
        font-size: 0.8rem;
    }
    
  
    
}