.divider-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-column: span 2;
  }
  
  .divider {
    position: relative;
    text-align: center;
    padding: 0.5rem 0;
  }
  
  .divider::before, 
  .divider::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 25%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .divider::before {
    left: 0;
    margin-left: 16px;
  }
  
  .divider::after {
    right: 0;
    margin-right: 16px;
  }