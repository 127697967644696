.headerTitle {
    display: flex;
    justify-content: center;
	position: relative;
}

.headerTitle img {
}
.headerTitle p{
    font-size: 50px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
}