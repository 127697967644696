.CompanyContents{
    background: #000F23;
    padding-top: 6%;
}
.CompanyContents .CompanyTopTxt{
    text-align: center;
}
.CompanyContents .CompanyTopTxt h1{
    color: #01BCD6;
    margin-block-start: 0;
}
.CompanyContents .CompanyTopTxt p{
    color: #fff;
    margin: 3% 0 10%;
}

/* 카드 */
.CompanyContents .HrCardContainer{
    margin: 3vW 0 5vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* 차트 */
.CompanyContents .CompanyChartTxt{}
.CompanyContents .CompanyChartTxt h1{
  color: #01BCD6;
  text-align: center;
}
.CompanyContents .CompanyChartTxt img{
    /* width: 956px; */
    margin: 10% auto;
    display: block;
    width: 90%;
}
@media (max-width: 1252px){
    .HrCard {
      width: 28%;
    }
    .containerBox {
      padding-top: 35%;
    }
  }