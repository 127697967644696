.M_footerSec{
  font-family: 'Noto Sans KR', sans-serif;
  width: 100%;
  align-items: center;
}
.NewFooterImg{
  max-width: 100%;
  height: auto;
}
.M_footerInfo {
  display: flex;
  justify-content: space-around;
  align-items: center;


}
.App-footer {
  padding: 0.6rem 1rem;
  color: #fff;
  flex-direction: row;
  background-color: #0000008c;
}
.M_footerTxt {
  text-align: left;
  font-size: 0.8rem;
}
.M_footerQR img {
  width: 100px;
  
}
/* ============모바일============ */
@media (max-width: 850px) {
.App-footer {
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 0 1rem;
}
.NewFooterImg{
  display: none;
}
.M_footerSec {
  display: block;
  text-align: left;
  padding: 0;
  background-color: #000F23;
}

.M_footerInfo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem;
  margin: 0;
}
.M_footerInfo .M_footerTxt p{
  color: #fff;
  font-size: 8.8px;
  margin: 0;
}
.M_footerQR{
  display: flex;
  justify-content: flex-end;
  width: 25%;

}
.M_footerQR img{
  width: 90%;
}

}
