html, body {
  margin: 0px;
  overflow-x: hidden;
  width: 100%;
}

body {
  /* background: url(../src/images/background_big.png) no-repeat; */
  /* background-image: url(../src/images/background_big_Basic.png) ; */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  /* padding-top: 20px;  */
  background-color: #000F23;
  font-family: 'Noto Sans KR', sans-serif;
}

.App {
  /* text-align: center; */
  max-width: 1920px;
  margin: 0 auto;
}

.PageContents {
  /* text-align: center;
  margin: 0 auto;
  width: 80%; */
}
.App-footer{
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 네비게이션 호버효과 */
.header__menulist a{
  position: relative;
  display: inline-block;
}
.header__menulist a::after {
  content: "";
  position: absolute;
  bottom: 0; /* 밑줄 위치 조정 */
  left: 0;
  width: 0; /* 초기 상태에서는 보이지 않도록 설정 */
  height: 1px; /* 밑줄 두께 설정 */
  background-color: #8BD2F4; /* 밑줄 색상 설정 */
  transition: width 0.3s ease-in-out;
}

.header__menulist a:hover::after {
 width: 100%; /* 호버 시 전체 너비로 확장 */
}
p{
  line-height: 1.5;
}
@media (max-width: 850px){
  body {
    background: none;
    background-color: #000F23;
    padding-top: 20px; 
  }
  .PageContents {
    width: 100%;
  }
  .App-header {
    width: 90%;
      /* padding-top: 0; */
    background-color: #000F23;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    font-size: 1.4rem;
    color: white;
    width: 80%;
    margin: 0 auto;
  }

}