body {
  font-family: 'Noto Sans KR', sans-serif;
}

.homePage {
  margin: auto;
  text-align: center;
  background: url(../../images/background_big.png) no-repeat;
  background-position: center 100%;

}

.homePage hr {
  margin:0;
  padding:0;
  border: none;
  border-top: 0.1px solid  rgba(223, 223, 223, 0.3);
}

.mainPage {
  padding: 7rem 3rem;
  border-top: 1px solid rgba(206, 206, 206, 0.5);
  border-bottom: 1px solid rgba(206, 206, 206, 0.5);
  font-family: 'Noto Sans KR', sans-serif;

}
@keyframes blinkThenAppear {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mainTitle {
  color: #fff;
  text-align: left;
  font-size: 3.3rem;
  font-weight: bold;

}
.mainSub {
  color: #fff;
  font-size: 2.2rem;
  text-align: left;
  margin-top: 5rem;
  font-weight: bold;
  animation: slideInFromLeft 2s ease-out; 
}
@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px); /* Starts 50 pixels left from its original position */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* Ends at its original position */
  }
}
.mainTitleAnts {
  font-size: 6rem;
  color: #4eeafe;
  margin-bottom: 4rem;
}
.mainSubR {
  color: #fff;
  text-align: right;
  font-size: 2.2rem;
  margin: 10rem 0rem;
  animation: slideInFromLeft 4s ease-out; 
}

.AntsnestSTR {
  font-weight: bold;
}
.coreValueTitle {
  font-size: 2.2rem;
  color: #4eeafe;
  font-weight: bold;
  margin: 1.5rem;
}




/* 푸터 */
.footerImg {
  width: 100%;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(70%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@media (max-width: 850px) {
  .mainPage {
    padding: 2rem;
  }
  .mainTitle {
    font-size: 1.4rem;
  }
  .mainSubR {
    font-size: 1.1rem;
    text-align: right;
    margin: 3rem 0rem;
  }
  .detail {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0rem 2rem;
  }
  .mainSub  {
    font-size: 1.1rem;
  }
  .mainTitleAnts {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
  .coreValues {
    color: #fff;
  }
  
  .challenge, .careful, .talent {
    background: linear-gradient(to top, rgb(22, 47, 82),  rgba(22, 47, 82, 0));
    border-radius: 1.2rem;
    padding: 1rem;
    margin-bottom: 2rem;

  }
  .coreValues .detail {
    text-align: center;
  }
  .coreValues p {
    font-weight: bold;
    font-size: 1.4rem;
  }
  .challengeTitle, .carefulTitle, .talentTitle {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 1rem;
    animation: slideUp 4s ease-out forwards ;

  }
  .challengeTitle {
    animation-delay: 4s; /* Adjust delay as needed */
  }
  
  .carefulTitle {
    animation-delay: 7s; /* Adjust delay as needed */
  }
  
  .talentTitle {
    animation-delay: 10s; /* Adjust delay as needed */
  }
  .coreValues img {
    width: 5rem;
  }
  
}

@media (max-width: 500px) {
  .mainPage {
    padding: 2rem;
  }
  .mainTitle {
    font-size: 1.4rem;
  }
  .mainSubR {
    font-size: 0.9rem;
    text-align: right;
    margin: 3rem 0rem;
  }
  .detail {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .mainSub  {
    font-size: 0.9rem;
  }
  .mainTitleAnts {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
  .coreValues {
    color: #fff;
  }
  
  .challenge, .careful, .talent {
    background: linear-gradient(to top, rgb(22, 47, 82),  rgba(22, 47, 82, 0));
    border-radius: 1.2rem;
    padding: 1rem;
    margin-bottom: 2rem;
  }
  .coreValues .detail {
    text-align: left;
    margin: 0rem 0.5rem;

  }
  .coreValues p {
    font-weight: bold;
    font-size: 1.4rem;
  }
  .challengeTitle, .carefulTitle, .talentTitle {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 1rem;
  }
  
  .coreValues img {
    width: 5rem;
  }
  
}



