.MainHeader{
    /* width: 64.1%; */
    /* margin: 1% auto; */
    background-color: #000F23;
}
.headerContainer{
    margin: 0 auto;
    padding: 0.5% 0;
    width: 64.1%;
    display: flex;
    justify-content: space-between;
}
.header__menulist{
    padding-inline-start: 0px;
}
.header__menulist a{
    margin: 0 15px;
} 
.header__menulist a li{
    list-style: none;
    color: #8BD2F4;
}

/* ============모바일============ */
@media (max-width: 1252px){
    .headerContainer{
        width: 80%;
    }
    .M_header__menulist {
        margin: 16px 0px 0px 0px;
    }
}
