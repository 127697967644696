.ServiceContents{
    background: url(../../images/background_big_Service.png) no-repeat;
    background-position: center 17%;
    padding-top: 6%;
    background-color: #000F23;
}
.ServiceContents .ServiceTopTxt{
    text-align: center;
}
.ServiceContents .ServiceTopTxt h1{
    color: #01BCD6;
    margin-block-start: 0;
    /* margin-top: 10%; */
}
.ServiceContents .ServiceTopTxt p{
    color: #fff;
    margin: 3% 0 10%;

}

/* 스크롤 */
.ServiceContents .TabBtn{
    display: flex;
    justify-content: center;
    gap: 2%;
}
.ServiceContents .TabBtn button{
    /* width: 100%; */
    padding: 1rem 5rem;
    border-radius: 56px;
    background: linear-gradient(to bottom, rgb(24, 57, 103),  rgb(28, 62, 108, .4));
    color: #fff;
    font-size: calc(7px + 1vmin);
    font-family: sans-serif;
    border: none;
}
.ServiceContents .TabBtn button:hover{
    background: linear-gradient(to bottom, rgb(1, 188, 214),  rgb(28, 62, 108 , .4)); 
}
.TabTitle{
    text-align: center;
    color: #01BCD6;
    margin-top: 5%;
}
.ITContents {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 5%;
    margin: 3% 0;
}

.ITContents:nth-child(2), .ITContents:nth-child(4){
    flex-direction: row-reverse;
}
.ITContents img{
    /* object-fit: contain; */
    margin-top: 1.5em;
    width: 58%;
}

/* 첫번째 */
.ITFirstBox h2{
    color: #01BCD6;
    display: flex;
    align-items: center;
    margin-bottom: 3%;
}
.ITFirstBox p{
    color: #fff;
}
.ITFirstBox .ITCircle{
    display: flex;
    justify-content: left;
    gap: 2%;
    margin-bottom: 5%;
}
.ITFirstBox .ITCircle p{
    background-color: #1A6C8F;
    border-radius: 20px;
    padding: 1% 2%;
}
.ITFirstBox .colorTitle{
    color: #01BCD6;
    font-weight: bold;
}

.ITFirstBox .gradationBox{
    width: 90%;
    background: linear-gradient(to bottom, rgb(22, 47, 82 ,1),  rgba(22, 47, 82, 0));
    border-radius: 20px;
    padding: 2% 4%;
    margin-bottom: 2%;
}


.gradationBoxes{
    display: flex;
    justify-content: space-between;
    gap: 2%;
}
.gradationBoxes .gradationBox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28%;
    gap: 5%;
    padding: 2%;
}
.gradationBoxes .gradationBox img{
    margin-top: 0;
    width: 100%;
}
.gradationBoxes .gradationBox:nth-child(1) img{
    width: 40%;
}

/* =======교육 서비스======= */
#edu-service{}
#edu-service .TabTitle{
    margin-bottom: 10%;
}
#edu-service .EduContents{}
#edu-service .EduContents .EduBoxFlex{
    display: flex;
    justify-content: space-between;
    gap: 2%;
}
#edu-service .EduContents .EduBoxFlex .EduBox{
    background: linear-gradient(to bottom, rgb(22, 47, 82 ,1),  rgba(22, 47, 82, 0));
    border-radius: 24px;
    padding: 5%;
    width: 30%;
}
#edu-service .EduContents .EduBoxFlex .EduBox img{
    width: 100%;
}
#edu-service .EduContents .EduBoxFlex .EduBox ul{
    padding-inline-start: 0;
}
#edu-service .EduContents .EduBoxFlex .EduBox li{
    list-style: none;
    display: flex;
    align-items: center;
    color: #fff;
}
#edu-service .EduContents .EduBoxFlex .EduBox li::before{
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: linear-gradient(to bottom, rgb(0, 191, 217),rgb(0, 191, 217 , 0) );
    border-radius: 20px;
    margin:  3% 5% 3% 0;
}

#edu-service .EduContents .EduBoxFinal{
    background: linear-gradient(to bottom, rgb(22, 47, 82 ,1),  rgba(22, 47, 82, 0));
    border-radius: 24px;
    padding: 2%;
}
#edu-service .EduContents .EduBoxFinal h3{
    color: #01BCD6;
}
#edu-service .EduContents .EduBoxFinal p{
    color: #fff;
}

@media (max-width: 1794px) {
    .ITContents {
        gap: 4%;
    }
}
@media (max-width: 1590px) {
    .ITContents {
        gap: 3%;
    }
}