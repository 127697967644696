.FutureIndustryContents{
    background: url(../../images/background_big_Future.png) no-repeat;
    background-position: center 17%;
    padding-top: 6%;
}
.FutureIndustryContents .FutureIndustryTopTxt{
    text-align: center;
}
.FutureIndustryContents .FutureIndustryTopTxt h1{
    color: #01BCD6;
    margin-block-start: 0;
    /* margin-top: 10%; */
    margin-block-end: 2em;
}
.FutureIndustryContents .FutureIndustryTopTxt p{
    color: #fff;
    margin: 3% 0 10%;
}

/* 컨텐츠 */
.FutureIndustryContents .FIContents{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5%;
}
.FutureIndustryContents .FIContents:nth-child(2) , .FutureIndustryContents .FIContents:nth-child(4){
    flex-direction: row-reverse;
}
.FutureIndustryContents .FIContents .ThumbnailViewer{
    width: 50%;
    margin-top: 3.5%;
}
.FutureIndustryContents .FIContents .FIFirstBox{
    width: 45%;
}
.FutureIndustryContents .FIContents .FIFirstBox h2{
    color: #01BCD6;
    margin-block-end: 0;
    margin-block-start: 0;
}
.FutureIndustryContents .FIContents .FIFirstBox .FICircle{
    display: flex;
    justify-content: left;
    gap: 2%;
    margin-bottom: 1%;
}
.FutureIndustryContents .FIContents .FIFirstBox .FICircle p{
    background-color: #1A6C8F;
    border-radius: 20px;
    padding: 1% 2%;
    color: #fff;
    font-size: calc(5px + 1vmin);
}
.FutureIndustryContents .FIContents .FIFirstBox .gradationBox{
    width: 90%;
    background: linear-gradient(to bottom, rgb(22, 47, 82 ,1),  rgba(22, 47, 82, 0));
    border-radius: 20px;
    padding: 2% 4%;
    margin-bottom: 2%;
    color: #fff;
}
.FutureIndustryContents .FIContents .FIFirstBox .gradationBox .colorTitle{
    color: #01BCD6;
    font-weight: bold;
}
.FutureIndustryContents .FIContents .FIFirstBox .gradationBox p{
    
}