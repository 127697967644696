body {
  overflow-x: hidden; 
  width: 100%;
  background-color: #000F23;
  background-position: center 50%;
}
.companyContainer {
  background: url(../../images/background_big.png) no-repeat;
  background-position: center 1%;


}
.contentHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* 화면 중앙에 위치하도록 설정 */
}
.txtBox p{
  color: #fff;
}
.txtBox{
  position: relative;
  padding-top: 10%;
}
.txtBox .headerImg, .txtBox .headerImg2, .txtBox .headerImg3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center; /* 애니메이션의 기준점을 요소의 중심으로 설정 */
  animation-duration: 1s; 
  animation-fill-mode: forwards; 
  display: block;
}
.txtBox .headerImg {
  animation-name: slideFromLeftAndFadeIn1; /* 위로 올라오며 서서히 진해지는 애니메이션 */
}

.txtBox .headerImg2 {
  animation-name: slideFromRightAndFadeIn2; /* 아래로 내려가며 서서히 진해지는 애니메이션 */
}

.txtBox .headerImg3 {
  animation-name: slideFromLeftAndFadeIn3;/* 위로 올라오며 서서히 진해지는 애니메이션 */
}

@keyframes slideFromLeftAndFadeIn1 {
  from { transform: translateX(-50%) translateY(-20%); opacity:0.2;}
  to { transform : translateX(-50%) translateY(-50%); opacity :1.0;}
}

@keyframes slideFromRightAndFadeIn2{
  from {transform : translateX(-50%) translateY(100%); opacity :0.2;}
  to {transform : translateX(-50%) translateY(300%); opacity :0.8;}
}
@keyframes slideFromLeftAndFadeIn3{
  from {transform : translateX(-50%) translateY(70%); opacity :0.2;}
  to {transform : translateX(-50%) translateY(50%); opacity :1.0;}
}




.memberContainer {
background-size: cover;
}
.MHrCardContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5vw 0vw;
  background-color: transparent;
  border-radius: 30px;
  width: 100%;
}
.mainIntro {
  font-size: 10px;
  font-weight: bold;
  margin-top: 15px;
}
.MorgChart{
  display: flex;
  justify-content: center;
}
.MorgChartImg {
  width: 100%;
}
.mBuinessChart {
  position: inherit;
  width: 90%;
  margin: 2rem 0rem;

}

@media (max-width: 760px) {
  .headerImg {
    width: 99%;
}
  .memberContainer {
    margin: 0;
    justify-content: space-between; 
  }
  .mainIntro {
    font-size: 1.2rem;
  }
    .MorgChartImg {
    width: 100%;
    
  }
  
}