.GalleryContainer{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(to bottom, rgb(22, 47, 82 ,1),  rgba(22, 47, 82, 0));
    border-radius: 24px;
    padding: 3%;
}
.GalleryContainer .BigSlide{
    position: relative;
    /* margin-left: 5%; */
}
.GalleryContainer .BigSlide .mySlides{
}
.GalleryContainer .BigSlide .mySlides img{
    /* width: 100%; */
}
.GalleryContainer .BigSlide .arrowBtn{

}
.GalleryContainer .BigSlide .arrowBtn .prev{
    position: absolute;
    left: 7%;
    top: 50%;
    transform: translateY(-50%);
}
.GalleryContainer .BigSlide .arrowBtn .next{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.GalleryContainer .BigSlide .arrowBtn a{
    font-size: calc(15px + 2vmin);
    cursor: pointer;
    color: #fff;
}
/* 작은 슬라이드 */
.GalleryContainer .row{
    width: 40%;
}
.demo.cursor{
    display: flex;
    justify-content: end;
    margin: 0 0 7%;
}
