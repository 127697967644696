.redirectGallery {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: 'Noto Sans KR', sans-serif;

}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
}

.container ul {
    list-style: none;
    font-size: 1.2rem;
    font-weight: bold;
  }
  

.image-section1 {
  width: 24.25%;
  margin-right: 1%;
  height: 760px;
  background-position: center;
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../images/FutureImg.png');
  background-size: 400%;
  transition: background-size .5s ease;
}

.image-section1:hover {
  background-size :450%; 
}

.image-section2 {
  width: 24.25%;
  margin-right: 0.5%;
  height: 760px;
  background-position: center;
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../images/SolutionImg.png');
  background-size: 400%;
  transition: background-size .5s ease;
}
.image-section2:hover {
  background-size :450%; 
}
.image-section3 {
  width: 24.25%;
  margin-left: 0.5%;
  height: 760px;
  background-position: center;
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../images/ServiceImg.png');
  background-size: 400%;
  transition: background-size .5s ease;
}
.image-section3:hover {
  background-size :450%; 
}
  
.image-section4 {
  width: 24.25%;
  margin-left: 1%;
  height: 760px;
  background-position: center;
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../images/NodeImg.png');
  background-size: 400%;
  transition: background-size .5s ease;
}
.image-section4:hover {
  background-size :450%; 
}

  .mainTitleF {
    font-size: 3rem;
    margin: 0;
  }
  .mainListF {
    list-style: none;
    margin-right: 3rem;
  }

  .mainTitleS {
    font-size: 3rem;
    margin: 0.1rem;
  }

  .mainListS {
    list-style: none;
    margin-right: 3rem;

  }
  .mainTitleService {
    font-size: 3rem;
  }
  .mainListService {
    list-style: none;
    margin-right: 3rem;


  }
  .mainTitleNode {
    font-size: 3rem;

  }
  .mainListNode {
    list-style: none;
    margin-right: 4rem;
  }

  .mTitle li {
    transition: font-size 0.3s ease-in-out; 
    white-space: nowrap;
    font-size: 1rem;
}
  .mTitle li:hover{
  }

@media (max-width: 960px) {
  .container {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  
  }
  .container div {
    flex-basis: calc(50% - 30px);
  }
  .image-section1 {
    width: 49%;
    height: 49.5%;
    margin: 0 0.5% 0 0;
    
  }
  .image-section2 {
    width: 49%;
    height: 49.5%;
    margin: 0 0 0 0.5%;

  }
  .image-section3 {
    width: 49%;
    height: 49.5%;
    margin: 0 0.5% 0 0;
  }
  .image-section4 {
    width: 49%;
    height: 49.5%;
    margin: 0 0 0 0.5%;
  }
  .mainTitleF, .mainTitleS, .mainTitleService, .mainTitleNode {
    font-size: 1.3rem;
    text-align: center;
  }
  .mainListF {
    margin-right: 5rem;
  }
  .mainListS {
    margin-right: 3.5rem;
  }
  .mainListService {
    margin-right: 1rem
  }
  .mainListNode {
    margin-right: 6rem;
  }
  .container ul {
    font-size: 0.9rem;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    
  }

}

  