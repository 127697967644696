.SolutionContents{
    background: url(../../images/background_big_Solution.png) no-repeat;
    background-position: center 17%;
    padding-top: 6%;
}
.SolutionContents .SolutionTopTxt{
    text-align: center;
}
.SolutionContents .SolutionTopTxt h1{
    color: #01BCD6;
    margin-block-start: 0;
    margin-bottom: 7%;
}
.SolutionContents .SolutionTopTxt h1 span{
    color: #fff;
}


/* 첫번째 섹션 */
.SolutionContents .SolutionContents01{
    color: #fff;
    margin-bottom: 15%;
}
.SolutionContents .SolutionContents01 .SolutionTxt{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2%;
    margin: 2% 0;
    border-radius: 24px;
}
.SolutionContents .SolutionContents01  .SolutionTxt h1{
    margin-bottom: 17%;
    margin-block-start: 0;
}
.SolutionContents .SolutionContents01 .SolutionTxt p{}
.SolutionContents .SolutionContents01 .SolutionTxt01{
    background: url(../../images/sol_Back01.png) no-repeat ;
}
.SolutionContents .SolutionContents01 .SolutionTxt02{
    background: url(../../images/sol_Back02.png) no-repeat ;
}
.SolutionContents .SolutionContents01 .SolutionTxt03{
    background: url(../../images/sol_Back03.png) no-repeat ;
}


/* 두번째 섹션 */
.SolutionContents .SolutionContents02{
}
.SolutionContents .SolutionContents02 .SolIconContents{
    display: flex;
    justify-content: center;
    gap: 2%;
}
.SolutionContents .SolutionContents02 .SolIconContents .SolImgTxt{
    width: 44%;
    background: linear-gradient(to bottom, rgb(22, 47, 82 ,1),  rgba(22, 47, 82, 0));
    border-radius: 24px;
    padding: 0 5%;
}
.SolutionContents .SolutionContents02 .SolIconContents .SolImgTxt img{
    display: block;
    margin: -24% auto 7%;
}
.SolutionContents .SolutionContents02 .SolIconContents .SolImgTxt .SolIconTxt{
    text-align: center;
}
.SolutionContents .SolutionContents02 .SolIconContents .SolImgTxt .SolIconTxt h2{
    color: #01BCD6;
    /* white-space: nowrap; */
    margin-bottom: 5%;
}
.SolutionContents .SolutionContents02 .SolIconContents .SolImgTxt .SolIconTxt p{
    color: #fff;
}

/* 세번째 섹션 */
.SolutionContents .SolutionContents03{
    /* background: url(../../images/solBottomBack.png) no-repeat; */
}
.SolutionContents .SolutionContents03 .Sol03ImgTxt{
    position: relative;
}
.SolutionContents .SolutionContents03 .Sol03ImgTxt img{
    display: block;
    margin: -10% auto 0;
    width: 100%;
}
.SolutionContents .SolutionContents03 .Sol03ImgTxt .Sol03Txt{
    text-align: center;
    color: #fff;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}
.SolutionContents .SolutionContents03 .Sol03ImgTxt .Sol03Txt h1{}
.SolutionContents .SolutionContents03 .Sol03ImgTxt .Sol03Txt p{}

.SolutionContents .SolutionContents03 .Sol03ImgTxt .Sol03TopTxt{
    margin-bottom: 25%;
    margin-top: 8%;
}

.SolutionContents .SolutionContents03 .Sol03ImgTxt .Sol03BottomTxt{}


/* 마지막 섹션 */
.SolutionContents .SolutionContents04{
    margin: -13% auto 5%;
}
.SolutionContents .SolutionContents04 h1{
    text-align: center;
    color: #01BCD6;
    margin-bottom: 5%;
}
.SolutionContents .SolutionContents04 .SolFinalImgs{}
.SolutionContents .SolutionContents04 .SolFinalImgs .SolFinalFlex{
    display: flex;
    justify-content: space-between;
    gap: 3%;
    margin: 3% 0;
}
.SolutionContents .SolutionContents04 .SolFinalImgs img{
    width: 50%;
}

@media (max-width: 1489px){
    .SolutionTxt p br{
        display: none;
    }
  }