body {
    font-family: 'Noto Sans KR', sans-serif;
    /* line-height: 1.6; */
    margin: 0;
    padding: 0;
    color: #000F23;
    overflow-x: hidden; 
    width: 100%;
}
.mainIntro {
    font-size: 1.2rem;
    margin-top: 5%;
}
form {
    max-width: 700px;
    margin: 1rem ;
    padding: 3rem;
    box-shadow: 0px 0px 30px 0px rgba(50, 50, 50, 0.1);
    background: linear-gradient(to bottom, rgb(22, 47, 82),  rgba(22, 47, 82, 0));
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-group {
    margin-bottom: 1rem;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
}

input, textarea {
    width: 320px;
    padding: 0.5rem;
    font-size: 1rem;
    background-color: #d8d8d8;
    box-sizing: border-box;
    border: none;
    border-radius: 0.4rem;
}

.contaxtSubmitBtn {
    width: 10rem;
    height: 2rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    align-items: center;
    background-color: #fff;
    color: #162f52;
    cursor: pointer;
    font-family: 'Noto Sans KR', sans-serif;


}
.contaxtSubmitBtn:hover {
    background-color: #01BCD6;
    color: #fff;

}

/* button {
    display: block;
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    border: none;
    background-color: #00BFD9;
    color: #fff;
    cursor: pointer;
    border-radius: 1rem;
    font-weight: bold;
}

button:hover {
    background-color: #252525;
} */

@media (max-width: 760px) {
    .headerImg {
        transform: scale(2);
        margin: 2.2rem 0rem;
    }
    .mainIntro {
        margin-top: 8%;
    }
    
}
@media (max-width: 550px) {
    .mainIntro {
        margin-top: 11%;
    }
}
@media (max-width: 480px) {
    .mainIntro {
        margin-top: 15%;
    }
}
@media (max-width: 380px) {
    .mainIntro {
        margin-top: 20%;
    }
}