.NodeMasterContents{
    background: url(../../images/background_big_Basic.png) no-repeat;
    background-position: center 17%;
    /* background-position: center top; */
    /* background-position: center center; */
    padding-top: 6%;
}
.NodeMasterContents .HomeContainer{
    
}
.NodeMasterContents .NodeMasterTopTxt{
    text-align: center;
}
.NodeMasterContents .NodeMasterTopTxt h1{
    color: #01BCD6;
    margin-block-start: 0;
    /* margin-top: 10%; */
}
.NodeMasterContents .NodeMasterTopTxt p{
    color: #fff;
    margin: 3% 0 10%;
}

.NodeMasterSection{}
/* 첫번째 섹션 */
.NodeMasterSection h1{
    color: #01BCD6;
    text-align: center;
}
.NodeMasterSection .NodeMasterFirt{
    width: 70%;
    margin: 0 auto 10%;
}
.NodeMasterSection .NodeMasterFirt .NodeImgBox{
    width: 100%;
    padding: 1%;
    text-align: center;
}
.NodeMasterSection .NodeMasterFirt .NodeImgBox img{
    /* height: auto; */
    width: 100%;
}
.NodeMasterSection .NodeMasterFirt .NodeImgBox img:nth-child(1){
    /* position: relative;
    width: 85%;
    margin: 5% 0; */
}
.NodeMasterSection .NodeMasterFirt .NodeImgBox img:nth-child(2){
    /* position: absolute;
    top: 70%;
    left: 50%;
    width: 21%;
    z-index: 1; */
}

.NodeMasterSection .NodeMasterFirt .NodeDownload{}
.NodeMasterSection .NodeMasterFirt .NodeDownload h3{
    color: #fff;
}

.NodeMasterSection .NodeMasterFirt .NodeDownload .NodeDownBtn{
    display: flex;
    justify-content: space-between;
}
.NodeMasterSection .NodeMasterFirt .NodeDownload .NodeDownBtn .NDBtn{
    width: 45%;
    border-radius: 50px;
    background: linear-gradient(to bottom, #006674,  rgb(4, 67, 75, .83));
    padding: 1.5% 2%;

}
.NodeMasterSection .NodeMasterFirt .NodeDownload .NodeDownBtn .NDBtn:hover {
    background: linear-gradient(to bottom, rgb(24, 57, 103), rgb(28, 62, 108, .4));
}
.NodeMasterSection .NodeMasterFirt .NodeDownload .NodeDownBtn .NDBtn a{
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
}

.NodeMasterSection .NodeMasterFirt .NodeDownload .NodeDownBtn .NDBtn a p{
    color: #fff;
    margin-block-start: 0;
    margin-block-end: 0;
    white-space: nowrap;
}
.NodeMasterSection .NodeMasterFirt .NodeDownload .NodeDownBtn .NDBtn a p span{
    color: #01BCD6;
    margin-right: 3%;
}

.NodeMasterSection .NodeMasterFirt .NodeDownload .NodeDownBtn .NDBtn a img{
    /* width: 5%; */
    object-fit: contain;
}

/* 두번째 섹션 */
.NodeMasterSection .NodeMasterSecond{}
.NodeMasterSection .NodeMasterSecond .NodeContents{
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 5%;
    margin: 5% 0;
}
.NodeMasterSection .NodeMasterSecond .NodeContents:nth-child(1){
    flex-direction: row-reverse;
}
.NodeMasterSection .NodeMasterSecond .NodeContents img{
    margin-top: 1.5em;
    width: 58%;
}
.NodeMasterSection .NodeMasterSecond .NodeContents:nth-child(1) img{
    width: 55.3%;
}
.NodeFirstBox {}
.NodeMasterSection .NodeMasterSecond .NodeContents:nth-child(1) .NodeFirstBox {
    width: 50%;
    margin-left: 0;
}
.NodeFirstBox h2{
    color: #01BCD6;
    margin-block-end: 0.9em;
}
.NodeFirstBox .NodeCircle{
    display: flex;
    justify-content: left;
    gap: 2%;
    margin-bottom: 5%;
}
.NodeFirstBox .NodeCircle p{
    background-color: #1A6C8F;
    border-radius: 20px;
    padding: 1% 2%;
    color: #fff;
    font-size: calc(5px + 1vmin);
}
.NodeFirstBox .gradationBox{
    width: 90%;
    background: linear-gradient(to bottom, rgb(22, 47, 82 ,1),  rgba(22, 47, 82, 0));
    border-radius: 20px;
    padding: 2% 4%;
    margin-bottom: 2%;
    color: #fff;
}
.NodeFirstBox .gradationBox .colorTitle{
    color: #01BCD6;
    font-weight: bold;
}