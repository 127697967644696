body {
  font-family: 'Noto Sans KR', sans-serif;

}
.MHrHrCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  border-radius: 8px;
  padding: 0.7rem;
  box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.2);
  /* background-color: #6b6b6b; */
  color: #fff;
}
  
.MHrHrCard img {
  border-radius: 50%;
  margin-bottom: 0.5rem;
  border-radius: 1rem;
  width:300px;
  height:300px;
}  
.MHrcontainerBox {
  text-align: center;
}  
.MHrtitle {
  color: #000;
}
  
.MHrcontainerBox > h2 {
  color: #fff;
  margin-bottom: 5px;
}

.MHrcontainerBox  p {
  color: #fff;
  margin-top: 5px;
}

.MHrWorktitle {
  font-weight: bold;
}
  
.MHrbutton {
  /* background-color: #7ebfce; */
  background-color: #fff;
  color: rgb(22, 47, 82);
  border: none;
  padding: 0.5rem 4rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;
  margin: 0rem 1rem;
  width: 60%;
}
  
.MHrbutton:hover {
  /* background-color: #252525; */
  background-color: #00BFD9;
}


/* @media (max-width: 960px) {
  .MHrCard {
    width: calc(50% - 40px);
    margin: 4px auto;
    padding: 2vw;
    max-width: 300px auto;
    min-width: 160px;
  }
.MHrCard img {
  margin-bottom: 0.5rem;
  border-radius: 1rem;
  width: 280px;
  height: 280px;
}
h2 {
  margin-bottom: 0px;
}
  
    p {
      margin-top: 0px;
    }
    .button {
      padding: 0.5rem;
      position: sticky;
      width: 100px;

    }
    .containerBox {
      grid-template-columns: minmax(50%, 2fr);
      font-size: 1rem;
    }
  } */
  @media (max-width: 849px) {
    .MHrCard {
      width: calc(45% - 40px);
      margin: 5px 4px auto;
      padding: 2vw 0vw;
      max-width: 350px auto;
      min-width: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: linear-gradient(to top, rgb(22, 47, 82),  rgba(22, 47, 82, 0));
      border-radius: 1rem;
  
    }
    .MHrCard img {
      margin-bottom: 0.5rem;
      border-radius: 10rem;
      max-width: 210px auto;
      width: 200px;
      height: 200px;
    }
    .MHrbutton {
      padding: 0.5rem 0.8rem;
      border-radius: 0.5rem;
    }
    .MHrcontainerBox {
      grid-template-columns: minmax(50%, 2fr);
      font-size: 0.9rem;
    }
  }

@media (max-width: 530px) {
  .MHrCard {
    width: calc(45% - 40px);
    margin: 5px 4px auto;
    padding: 2vw 0vw;
    max-width: 350px auto;
    min-width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(to top, rgb(22, 47, 82),  rgba(22, 47, 82, 0));
    border-radius: 1rem;

  }
  .MHrCard img {
    margin-bottom: 0.5rem;
    border-radius: 10rem;
    max-width: 210px auto;
    width: 150px;
    height: 150px;
  }
  .MHrbutton {
    padding: 0.2rem 0.8rem;
  }
  .MHrcontainerBox {
    grid-template-columns: minmax(50%, 2fr);
    font-size: 0.7rem;
  }
}
