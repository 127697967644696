body {
  font-family: 'Noto Sans KR', sans-serif;
}
.HrCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1%;
    width: 30%;
    /* width: 370px; */
    height: 412px;
    /* margin: 0.8rem 0.1rem; */
    padding: 1rem;
    /* background: linear-gradient(to bottom, rgb(22, 47, 82),  rgba(22, 47, 82, 0)); */
    color: #000;
    position: relative;
    margin-bottom: 4rem;
  }
  
  .HrCard img {
    border-radius: 50%;
    margin-bottom: 0.5rem;
    width:176px;
    height:176px;
    position: absolute;
    left: 50% ;
    top: 0;
    transform: translateX(-50%);
    z-index: 2;
  }
  
  .containerBox {
    text-align: center;
    color: #fff;
    background: linear-gradient(to bottom, rgb(22, 47, 82),  rgba(22, 47, 82, 0));
    border-radius: 24px;
    padding-top: 20%;
    /* margin-top: -20%; */
    position: absolute;
    top: 21%;
    /* width: 380px; */
    width: 90%;
  }
  
h2 {
  margin-bottom: 5px;
}

p {
  margin-top: 5px;
}

  
.HrCard .button {
  /* background-color: #7ebfce; */
  background-color: #01BCD6;
  color: #fff;
  border: none;
  padding: 0.8rem 0;
  border-radius: 56px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 50%;
  margin: 5% auto 0;
}
  
.HrCard .button:hover {
  /* background-color: #252525; */
  background-color: #162f52;
}
@media (max-width: 1497px){
  .HrCard {
    width: 29%;
    /* width: 370px; */
  }
  .containerBox {
    padding-top: 30%;
  }
}
@media (max-width: 1252px){
  .HomeContainer{
    width: 80%;
}
}
